@font-face {
    font-family: RobotoCondensed;
    src: url(assets/roboto-condensed.light.ttf);
    font-weight: 800;
}

@font-face {
    font-family: EconomicaBold;
    src: url(assets/economica-bold.ttf);
    font-weight: 800;
}

@font-face {
    font-family: BreeSerif;
    src: url(assets/BreeSerif-Regular.ttf);
    font-weight: 800;
}

@font-face {
    font-family: Lato;
    src: url(assets/Lato-Regular.ttf);
    font-weight: 800;
}

@font-face {
    font-family: Rokkitt;
    src: url(assets/Rokkitt-Regular.ttf);
    font-weight: 800;
}

* {
    margin: 0;
    padding: 0;
}

body, html {
    text-align: center;
    font-size: 20px;
    font-family: 'RobotoCondensed';
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0%;
    scroll-behavior: smooth;
}

h1, h2, h3, h4, h5 {
    font-family: 'BreeSerif';
    text-decoration: none;
}

/* ========= Generic CSS ========= */
.section-title {
    padding-top: 90px;
    padding-bottom: 50px;
}

.section-title h2 {
    position: relative;
    text-align: center;
    color: #333;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
}

.section-title h2:after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 60px;
    height: 2px;
    background-color: #ffa673;
    margin-left: -30px;  
}

.reg-button,
.reg-button:visited,
.reg-button:focus {
    width: auto !important;
    margin: 5px;
}
/* ========= Navigation Bar ========= */
.navbar-default {
    background-color: transparent;
    border-color: #444;
    z-index: 110;
    transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -webkit-transition: all 1s;
}

.navbar-default .navbar-nav > li > a {
    color: #b5b5b5;
    font-family: 'BreeSerif';
    transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -webkit-transition: all 1s;
}

.navbar-default .navbar-nav > li > a:hover {
    color: #ffa673;
}

.navbar-default .navbar-nav > li > a:focus {
    color: #b5b5b5;
}

.navbar-brand {
    padding: 9px 15px;
}

.navbar-brand img {
    transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -webkit-transition: all 1s;
}

.navbar-default .navbar-nav > .active>a,
.navbar-default .navbar-nav > .active>a:hover,
.navbar-default .navbar-nav > .active>a:focus {
    background-color: transparent;
    color: #ffa673;
}

/* ========= Header ========= */

.header {
    background-image: url(assets/bg3.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
}

.header-overlay {
    background-color: rgba(255, 255, 255, 0.65);
}

.logo-itself {
    vertical-align: middle;
    width: 600px;
    max-height: 100%;
    max-width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

/* ========= Contact Me ========= */

.contact-me-form .form-control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 21px;
    color: #555;
    background-color: transparent;
    border: 1px solid #aeaeae;
    border-radius: 3px;
    margin-bottom: 15px;
    opacity: .8;
    outline: 0px !important;
    transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -webkit-transition: all 1s;
}

.form-control:focus {
    box-shadow: 0px 0px 8px rgba(255, 166, 115, 0.5);
} 

#message {
    height: 117px;
    resize: none;
}

.submit-btn,
.submit-btn:active,
.submit-btn:focus,
.submit-btn:visited {
    background-color: transparent;
    border: 1px solid #ffa673;
    border-radius: 4px;
    width: 100%;
    height: 50px;
    text-transform: uppercase;
    font-size: 18px;
    color: #ffa673;
    transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -webkit-transition: all 1s;
}

.submit-btn:hover {
    background: #ffa673;
    color: #FFF;
    border-color: #ffa673;
}

/* ========= Footer ========= */

.footer {
    background-color: #1a1a1a;
    padding-bottom: 35px;
    padding-top: 35px;
    border-bottom: 3px solid #ffa673;
}

.copyright {
    width: 50%;
}

.copyright p {
    color: #8C8B8A;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
}

.scroll-top {
    text-align: right;
}

.scroll-top a {
    background-color: #ffa673;
    padding: 25px;
}

.scroll-top a i {
    color: #FFF;
    font-size: 25px;
}


/* ========= About Me ========= */
.download-cv-form {
    padding-bottom: 30px;
}

/* ========= Projects ========= */
.project {
    display: inline-block;
    width: 300px;
    margin: 10px;
}

.project a h3 {
    color: #b35900;
    padding-bottom: 10px;
}

.project a:hover {
    text-decoration: none;
}

.project a img {
    margin-bottom: 10px;
    width: 120px;
    object-fit: cover;
    border-radius: 100px;
}



.profile {
    width: 170px;
    height: 170px;
    border-radius: 100px;
    border: solid black 1px;
}

.section—first {
    height: 100vh;
    position: relative;
}

.section {
    position: relative;
}

.bg {
    background-color: rgb(227, 250, 227);
}

.mm {
    text-align: center;
    font-size: 20px;
    padding: 2.5%;
    font-family: 'RobotoCondensed';
}


/* ========= CV ========= */

html.cv,body.cv,div.cv,span.cv,h1.cv,h2.cv,h3.cv,h4.cv,h5.cv,h6.cv,p.cv,em.cv,img.cv,ol.cv,ul.cv,li.cv,form.cv,caption.cv,article.cv,details.cv,footer.cv,header.cv,nav.cv,section.cv {
    border:0;
	font:inherit;
	font-size:100%;
	margin:0;
	padding:0;
	vertical-align:baseline;
    text-align: left;
    
}

article.cv,footer.cv,header.cv,nav.cv,section.cv {
	display:block;
}

.cv * {
    font-family: 'Lato', helvetica, arial, sans-serif;
    font-size: 16px;
    color: #222;
    margin-top: 0px;
    margin-bottom: 0px;
}

.clear {clear: both;}

.cv p {
	font-size: 1em;
	line-height: 1.4em;
	margin-bottom: 20px;
	color: #444;
}

#cv {
    width: 90%;
    max-width: 800px;
    background: #f3f3f3;
    margin: 30px auto;
    -webkit-box-shadow: 6px 4px 5px 0px rgba(0,0,0,0.38);
    -moz-box-shadow: 6px 4px 5px 0px rgba(0,0,0,0.38);
    box-shadow: 6px 4px 5px 0px rgba(0,0,0,0.38);
}

.mainDetails {
    padding: 25px 35px;
    border-bottom: 2px solid #cf8a05;
    background: #ededed;
}

#name h1 {
    font-size: 2.5em;
    font-weight: 700;
    font-family: 'Rokkitt', Helvetica, Arial, sans-serif;
    margin-bottom: -6px;
}

#name h2 {
    font-size: 2em;
    margin-left: 2px;
    font-family: 'Rokkitt', Helvetica, Arial, sans-serif;
}

#mainArea {
    padding: 0 40px;
}

#headshot {
    width: 12.5%;
    float: left;
    margin-right: 30px;
}

#headshot img {
    width: 100%;
    height: auto;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}

#name {
    float: left;
}

#contactDetails {
    float: right;
}

#contactDetails ul {
    list-style-type: none;
    font-size: 0.9em;
    margin-top: 2px;
}

#contactDetails ul li {
    margin-bottom: 3px;
    color: #444;
}

#contactDetails ul li a, a[href^=tel] {
    color: #444; 
    text-decoration: none;
    -webkit-transition: all .3s ease-in;
    -moz-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    -ms-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

#contactDetails ul li a:hover { 
    color: #cf8a05;
}


section {
    border-top: 1px solid #dedede;
    padding: 20px 0 0;
}

section:first-child {
    border-top: 0;
}

section:last-child {
    padding: 20px 0 10px;
}

.sectionTitle {
    float: left;
    width: 25%;
}

.sectionContent {
    float: right;
    width: 72.5%;
}

.sectionTitle h1 {
    font-family: 'Rokkitt', Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 1.5em;
    color: #cf8a05;
}

.sectionContent h2 {
    font-family: 'Rokkitt', Helvetica, Arial, sans-serif;
    font-size: 1.5em;
    margin-bottom: -2px;
}

p.subDetails {
    font-size: 0.8em;
    font-style: italic;
    margin-bottom: 3px;
}

.keySkills {
    list-style-type: none;
    -moz-column-count:3;
    -webkit-column-count:3;
    column-count:3;
    margin-bottom: 20px;
    font-size: 1em;
    color: #444;
}

.keySkills ul li {
    margin-bottom: 3px;
}

@media all and (min-width: 602px) and (max-width: 800px) {
    #headshot {
        display: none;
    }
    
    .keySkills {
    -moz-column-count:2;
    -webkit-column-count:2;
    column-count:2;
    }
}

@media all and (max-width: 601px) {
    #cv {
        width: 95%;
        margin: 10px auto;
        min-width: 280px;
    }
    
    #headshot {
        display: none;
    }
    
    #name, #contactDetails {
        float: none;
        width: 100%;
        text-align: center;
    }
    
    .sectionTitle, .sectionContent {
        float: none;
        width: 100%;
    }
    
    .sectionTitle {
        margin-left: -2px;
        font-size: 1.25em;
    }
    
    .keySkills {
        -moz-column-count:2;
        -webkit-column-count:2;
        column-count:2;
    }
}

@media all and (max-width: 480px) {
    .mainDetails {
        padding: 15px 15px;
    }
    
    section {
        padding: 15px 0 0;
    }
    
    #mainArea {
        padding: 0 25px;
    }

    
    .keySkills {
    -moz-column-count:1;
    -webkit-column-count:1;
    column-count:1;
    }
    
    #name h1 {
        line-height: .8em;
        margin-bottom: 4px;
    }
}

@media print {
    #cv {
        width: 100%;
    }
}

@-webkit-keyframes reset {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes reset {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes fade-in {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes reset {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.instaFade {
    -webkit-animation-name: reset, fade-in;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-timing-function: ease-in;
    
    -moz-animation-name: reset, fade-in;
    -moz-animation-duration: 1.5s;
    -moz-animation-timing-function: ease-in;
    
    animation-name: reset, fade-in;
    animation-duration: 1.5s;
    animation-timing-function: ease-in;
}

.quickFade {
    -webkit-animation-name: reset, fade-in;
    -webkit-animation-duration: 2.5s;
    -webkit-animation-timing-function: ease-in;
    
    -moz-animation-name: reset, fade-in;
    -moz-animation-duration: 2.5s;
    -moz-animation-timing-function: ease-in;
    
    animation-name: reset, fade-in;
    animation-duration: 2.5s;
    animation-timing-function: ease-in;
}

.delayOne {
    -webkit-animation-delay: 0, .5s;
    -moz-animation-delay: 0, .5s;
    animation-delay: 0, .5s;
}

.delayTwo {
    -webkit-animation-delay: 0, 1s;
    -moz-animation-delay: 0, 1s;
    animation-delay: 0, 1s;
}

.delayThree {
    -webkit-animation-delay: 0, 1.5s;
    -moz-animation-delay: 0, 1.5s;
    animation-delay: 0, 1.5s;
}

.delayFour {
    -webkit-animation-delay: 0, 2s;
    -moz-animation-delay: 0, 2s;
    animation-delay: 0, 2s;
}

.delayFive {
    -webkit-animation-delay: 0, 2.5s;
    -moz-animation-delay: 0, 2.5s;
    animation-delay: 0, 2.5s;
}
